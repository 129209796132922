export default {
  breakpoints: ["40em", "58em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    blue: "#07c",
    lightgray: "#f6f6ff",
    yell: "#DBA11A",
    gree: "#0C8346",
    greeDark: "#074B28",
    plain: "#C2CED6",
    eblue: "#0E34A0",
    plainDark: "#859DAD",
    oran: "#F06449",
    oranLight: "#FDEFEC",
    plainLight: "#f6f7f9",
    plainGree: "#f6f7f9",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "Share",
    slab: "OpenSans Condensed, sans-serif",
    monospace: "Share Tech Mono, monospace",
    tech: "Share Tech Mono, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {},
  text: {},
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
    },
  },
};
